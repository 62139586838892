<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<{
  header?: {
    title?: string
    icon?: string
    description?: string
  }
  content?: string

  /**
   * Show the overlay
   *
   * @default true
   */
  overlay?: boolean
  /**
   * Prevent the modal from closing
   *
   * @default false
   */
  preventClose?: boolean
  /**
   * Maximum width of the modal
   *
   * @example max-w="sm:4xl"
   */
  maxW?: string
  /**
   * Minimum width of the modal
   *
   * @example min-w="sm"
   */
  minW?: string
  /**
   * Maximum height and width of the modal
   *
   * @example maxHW="sm:4xl"
   */
  maxHw?: string
  /**
   * Minimum height of the modal
   *
   * @example h="md"
   */
  h?: string

  /**
   * Show the header
   *
   * @default true
   */
  showHeader?: boolean

  una?: {
    modalFooterClass?: string
    modalHeaderClass?: string
    modalBodyClass?: string
  }
}>(), {
  showHeader: true,
  overlay: true,
  preventClose: false,
  maxW: 'sm:4xl',
})

const emit = defineEmits(['closePrevented', 'close'])

const modelValue = defineModel<boolean>()

function close() {
  if (props.preventClose) {
    emit('closePrevented')

    return
  }

  modelValue.value = false
  emit('close')
}
</script>

<template>
  <TransitionRoot
    appear
    :show="modelValue"
    as="template"
  >
    <Dialog
      as="div"
      class="relative z-1000"
      @close="close"
    >
      <TransitionChild
        v-if="overlay"
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray/50 blur-20" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto py-1">
        <div
          class="min-h-full flex items-center justify-center"
        >
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              v-bind="$attrs"
              border="~ base 1"
              rounded="md"
              :max-w="maxW"
              :min-w="minW"
              :h="h"
              class="mx-1 max-h-98dvh flex flex-col overflow-hidden bg-base sm:w-full divide-y divide-base"
            >
              <div
                class="flex items-center justify-between p-6"
                :class="una?.modalHeaderClass"
              >
                <slot
                  v-if="showHeader"
                  name="header"
                >
                  <div class="flex grow items-start justify-between space-x-1">
                    <div class="flex grow">
                      <slot
                        name="header-title"
                      >
                        <GIcon
                          v-if="header?.icon"
                          :name="header?.icon"
                          size="3xl"
                          class="mr-2"
                        />

                        <div>
                          <DialogTitle
                            as="h3"
                            class="text-base"
                          >
                            {{ header?.title }}
                          </DialogTitle>

                          <p class="text-sm text-muted">
                            {{ header?.description }}
                          </p>
                        </div>
                      </slot>
                    </div>

                    <slot name="close">
                      <GButton
                        label="i-close"
                        icon
                        btn="ghost-gray"
                        square
                        size="xs"
                        @click="$emit('close')"
                      />
                    </slot>
                  </div>
                </slot>
              </div>

              <div
                class="scroll-p-20 overflow-auto p-6"
                :class="una?.modalBodyClass"
              >
                <slot>
                  <p class="text-muted">
                    {{ content }}
                  </p>
                </slot>
              </div>

              <!-- !TODO: add actions/footer slot -->
              <div
                class="mt-auto p-6"
                :class="una?.modalFooterClass"
              >
                <slot
                  name="footer"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
